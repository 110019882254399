import React from 'react';
import logo from '../assets/logo.svg';

import { t } from '../lang';
const PrivacyPolicy = () => {
  return (
    <>
      <header style={{ textAlign: 'center', padding: 30 }}>
        <a href="/"><img src={logo} alt="road24" /></a>
      </header>
      <div className="privacy" style={{ padding: '20px 60px 40px' }}>
        <p>
          <strong>Оммавий Оферта</strong><br />
          (Роад 24 платформаси учун)</p>

        <p>Тошкент ш.<br />
          01.01.2024</p>

        <p>Ушбу Оферта чексиз миқдордаги юридик ва жисмоний шахсларга, “КАШ АПП” МЧЖ (кейинги ўринларда Маъмурият ёки Оператор деб юритилади) томонидан “«Роад24»” платформасидан фойдаланиш бўйича хизмат кўрсатиш тўғрисидаги шартнома тузишга қаратилган расмий оммавий оферта ҳисобланади. тақдим этиш бўйича хизматлар кўрсатиш.</p>

        <p>Ушбу Офертада кўрсатилган ҳаракатларнинг бажарилиши – платформада рўйҳатдан ўтадиган шахслар томонидан ушбу Офертада (кейинги ўринларда Шартнома деб юритилади) белгиланган шартларда, тартибда ва ҳажмда хизматлар кўрсатиш шартномасини тузишга розилигини (қабул қилишини) сўзсиз ва шартиз тасдиқлашдир.</p>

        <p><strong>1. АСОСИЙ ТУШУНЧАЛАР</strong></p>

        <p>1.1. Ушбу Шартномада қуйидаги асосий тушунчалардан фойдаланилади:</p>

        <p>“КАШ АПП” МЧЖ томонидан тақдим этилган маъмурият (сайт маъмурияти ёки оператори) «Роад24» платформасини ишлаб чиқарувчиси ҳисобланади.</p>

        <p>«Роад24» платформаси (веб-сайт (https://Road24.uz) ва илова ёки матнда Хизмат, Сайт) фойдаланувчиларга бир қатор инновацион ва бошқа хизматларни олиш имконини берувчи онлайн платформа (компютер учун) кўринишидаги дастурдир. Платформа расмий рўйхатдан ўтган муаллифлик ҳуқуқи обекти ҳисобланади.</p>

        <p>Фойдаланувчи – Платформа ва унинг функцияларидан фойдаланишни бошлаган, хизматлардан фойдаланаётган ва ҳақ тўлаётган, Платформада ўз шахсий кабинетига эга бўлган жисмоний ёки юридик шахс;</p>

        <p>Ҳамкор ташкилотлар - банклар, суғурта ташкилотлари, реклама берувчилар ва ўз хизматларини «Роад24» платформаси орқали тақдим этиладиган бошқа ташкилотлар;</p>

        <p>Хизматлар (хизмат) - «Роад24» платформаси орқали Фойдаланувчиларга кўрсатиладиган воситачилик, ахборот ва маслаҳат хизматлари;</p>

        <p>1.2. Ушбу Шартнома 1.1-бандда кўрсатилмаган шартлардан ҳам фойдаланиши мумкин. Бундай атамаларнинг маъноси ушбу фуқаролик ва бошқа қонун ҳужжатларига ҳамда Шартнома матнига мувофиқ тушунилиши керак.</p>

        <p><strong>2. ҚАБУЛ ҚИЛИШ ТАРТИБИ</strong></p>

        <p>2.1. Ушбу Оммавий офертани қабул қилиш Фойдаланувчининг «Роад24» платформасида рўйхатдан ўтганлигини тасдиқлаш орқали амалга оширилади. Платформа ИОС, Андроид платформаларида ва www.»Роад24».уз сайтида ишлайди. Шартнома таклиф қабул қилинган пайтдан бошлаб тузилган ҳисобланади.</p>

        <p>2.2. Агар Фойдаланувчи ушбу Шартнома шартларига рози бўлмаса, у Сайтдан (Платформа) фойдаланишни тўхтатиши керак.</p>

        <p>2.3. Агар Фойдаланувчи ушбу таклифни бузса, Сайт маъмурияти бир томонлама тартибда ушбу Шартномани тўлиқ ёки қисман бекор қилиши ёки чекловлар қўйиши мумкин.</p>

        <p>2.4. Платформа томонидан кўрсатиладиган хизматларнинг тўхтатилиши Маъмурият ва Фойдаланувчи ўртасидаги шартноманинг бекор қилинишини англатади.</p>

        <p>2.5. Оферта ҳудуди Ўзбекистон Республикаси (кейинги ўринларда Ўз. Рес. деб юритилади) ҳисобланади.</p>

        <p><strong>3. ШАРТНОМА МАҚСАДИ</strong></p>

        <p>3.1. Ушбу Шартноманинг предмети – Платформада Фойдаланувчиларни рўйхатдан ўтказиш орқали мижозлар (фойдаланувчилар) томонидан хизматлар олиш соҳасида томонларнинг ўзаро манфаатли ҳамкорлиги ҳисобланади. Шу жумладан автотранспорт воситалари ойналарининг тусини ўзгартириш (қорайтириш) учун рухсатномалар, суғурта полиси ва бошқалар.</p>

        <p>3.1.1. Қонунчилик ҳужжатларида белгиланган тартибда автотранспорт воситалари ойналарининг тусини ўзгартириш (қорайтириш) учун рухсатномалар, суғурта полиси олиш бўйича ҳужжатлар олиш имконияти синов жараёнида амалга оширилади.</p>

        <p>
          3.2. Ушбу Шартномага мувофиқ, Фойдаланувчи Платформанинг пуллик хизматларидан фойдаланади, Фойдаланиш шартларига риоя қилади ва белгиланган тартибда кўрсатилган пуллик хизматлар учун воситачилик ҳақини тўлаш мажбуриятини олади. Маъмурият Платформанинг узлуксиз ишлашини таъминлайди, Фойдаланувчиларга Платформа хизматлари ва имкониятларидан пуллик фойдаланишни таъминлайди ва белгиланган тартибда тўловларни олади. Шу билан бирга, Оператор Платформанинг фунционаллигида назарда тутилган, Ўзбекистон Республикаси қонунчилигида тақиқланмаган хизматлар ва бошқа операцияларни амалга оширишда фақат техник ва ахборот ўзаро ҳамкорлигини таъминлайди ҳамда ҳамкор ташкилотлар фойдасига мижозларнинг жорий ҳисоб варақларидан (карталар) тегишли маблағларни ечиш ва ҳамкор ташкилотларга ўтказишни ўз зиммасига олмайди.</p>

        <p>3.3. Шартномада тўғридан-тўғри назарда тутилмаган барча ҳолларда томонлар Ўзбекистон Республикасининг амалдаги қонунчилигига ва тадбиркорлик иш юритиш одатларига риоя қилишлари шарт. Ушбу Шартноманинг фойдаланувчи томонидан қабул қилиниши қуйидаги ҳужжатларни ҳам қабул қилинганлигини англатади:</p>

        <p>- “Роад24” фойдаланувчиларининг шахсий маълумотлари учун махфийлик сиёсати” (ҳттпс://»Роад24».уз/приваcй-полиcй/ манзилида жойлашган). Ушбу ҳужжат ушбу Битимнинг ажралмас қисми ҳисобланади.</p>

        <p>3.4. Агар ҳужжатларнинг янги таҳрирларида бошқача тартиб назарда тутилган бўлмаса, ҳужжатларнинг янги таҳрирлари улар эълон қилинган кундан бошлаб кучга киради. Фойдаланувчи Шартномага ўзгартириш ва қўшимчалар киритганидан кейин ҳам платформа хизматларидан фойдаланишда давом этаётгани ўзгартириш ва қўшимчалар у томонидан қабул қилинганлигини билдиради.</p>

        <p>3.5. Платформа хизматларидан фойдаланишдан олдин Фойдаланувчи ушбу таклиф билан тўлиқ танишиши ва тариф режасида кўрсатилган нархлар билан танишиши керак. Ушбу Оммавий офертани қабул қилиш Фойдаланувчини Платформада рўйхатдан ўтказиш йўли билан амалга оширилади ва Ўзбекистон Республикаси Фуқаролик кодексининг 357, 364, 365, 366, 367, 369 ва 370-моддаларига мувофиқ Офертага тўлиқ ва сўзсиз риоя этилишини билдиради. .</p>

        <p>3.6. Ушбу Шартнома очиқ ва ҳамма учун умуммаълум ҳужжатдир.</p>

        <p>3.7. Ушбу Шартномани тузиш орқали Фойдаланувчи Ўзбекистон Республикасининг шахсий маълумотлар тўғрисидаги қонунчилиги талабларига мувофиқ Операторга унинг шахсий маълумотларини қайта ишлашга тўлиқ, чексиз розилигини тақдим этади. Операторнинг Интернет-ресурсида жойлаштирилган шахсий маълумотларни ҳимоя қилиш ва қайта ишлаш ҳуқуқини беради.</p>

        <p>3.8. Ушбу таклиф Ўзбекистон Республикасининг Фуқаролик кодекси, Ўзбекистон Республикасининг “Электрон тижорат тўғрисида”, “Электрон ҳужжат айланиши тўғрисида”ги қонунлари ва бошқалар асосида ишлаб чиқилган.</p>

        <p><strong>4. ПЛАТФОРМА СТАТУСИ</strong></p>

        <p>4.1. Платформа – ҳттпс://»Роад24».уз манзилида Интернет тармоғида бундай маълумотларни тақдим этувчи компютер дастурлари ва маълумотлар тўпламини акс эттирадиган интернет-ресурс (ахборот воситачиси, веб-сайт, хизмат).</p>

        <p>4.2. ҳттпс://»Роад24».уз (домен номи) тармоқ манзили ва ундан фойдаланиш билан боғлиқ барча ҳуқуқлар платформа маъмуриятига тегишли. Манфаатдор шахсларнинг Сайтга кириши Ўзбекистон Республикасининг амалдаги қонунчилигига мувофиқ ушбу Шартнома билан белгиланади.</p>

        <p>4.3. Интеллектуал фаолият натижаларидан фойдаланиш ҳуқуқи Маъмуриятга тегишли.</p>

        <p>4.4. Жисмоний ва юридик шахсларнинг ушбу Шартнома бўйича ёки Сайт фаолияти билан боғлиқ ариза, таклиф ва шикоятлари, шунингдек, ваколатли органларнинг сўровлари Маъмуриятнинг электрон почта манзилига ёки суппорт@»Роад24».уз электрон почта манзилига ёки фойдаланувчиларни қўллаб-қувватлаш хизматидаги рақамлар орқали қабул қилинади.</p>

        <p>
          4.5. Ушбу Шартнома ҳеч қандай тарзда Фойдаланувчига компания номи, савдо белгиси, домен номи ва Сайт маъмуриятининг бошқа таниқли белгиларидан фойдаланиш ҳуқуқини бермайди. Сайт маъмуриятининг компания номи, товар белгиси, домен номи ва бошқа таниқли белгиларидан фойдаланиш ҳуқуқи Сайт маъмуриятининг ёзма розилиги билан берилиши мумкин.</p>

        <p>4.6. Сайтни бошқариш ва ривожлантириш Ўзбекистон Республикаси қонунчилигига мувофиқ Маъмурият томонидан амалга оширилади.</p>

        <p>4.7. Платформа маъмурияти фойдаланувчиларнинг таклифларини инобатга олган ҳолда қўшимча хизматлар кўрсатиши, платформанинг имкониятларини янада кенгайтириши мумкин.</p>

        <p><strong>5. ТОМОНЛАРНИНГ ҲУҚУҚ ВА МАЖБУРИЯТЛАРИ</strong></p>

        <p>5.1. Маъмурият ва Фойдаланувчи(лар) ушбу Шартнома тарафлари ҳисобланади.</p>

        <p>5.2. Ушбу Шартнома тарафлари қуйидаги ҳуқуқларга эга:</p>

        <p>5.2.1. Платформа маъмурияти қуйидаги ҳуқуқларга эга:</p>

        <p>- Фойдаланувчи томонидан хизмат кўрсатиш учун уланишни ўрнатиш, қабул қилиш, рухсат бериш ёки Платформада белгиланган тартибда уларни бекор қилиш;</p>

        <p>- пуллик хизматлар учун тўловларни ундириш;</p>

        <p>- ўз маблағи ва ўз ихтиёри билан платформада Фойдаланувчиларга таклиф этилаётган хизматларни илгари суриш мақсадида реклама кампанияларини ўтказиш;</p>

        <p>- Мижозларни қидириш ва уларга Фойдаланувчи хизматларини таклиф қилиш, шунингдек уларни хизматлар кўрсатиш шартлари билан таништириш.</p>

        <p>5.2.2. Фойдаланувчи қуйидаги ҳуқуқларга эга:</p>

        <p>- белгиланган шартларга мувофиқ Платформа хизматларини қабул қилиш;</p>

        <p>- келишилган шартлар асосида платформага кириш ҳуқуқига эга бўлиш;</p>

        <p>- зарур ҳолларда маъмуриятдан тушунтиришлар ва ёрдам олиш.</p>

        <p>5.3. Ушбу Шартнома тарафлари қуйидаги мажбуриятларни бажарадилар.</p>

        <p>5.3.1. Платформа маъмурияти (оператори) қуйидаги мажбуриятларни бажаради:</p>

        <p>- ушбу Шартнома шартларига мувофиқ хизматлар учун тўловни ўз вақтида тўлаш;</p>

        <p>- ушбу Шартномада назарда тутилган талаблар ва стандартларга қатъий мувофиқ ҳаракат қилиш, шунингдек, Ўзбекистон Республикасининг хизмат кўрсатиш соҳасидаги амалдаги қонунчилигига қатъий риоя қилиш;</p>

        <p>- Ахборот параметрларини қабул қилиш, текшириш ва жўнатиш бўйича техник талабларга, шунингдек, Платформа билан ишлаш қоидаларига қатъий риоя қилган ҳолда ҳаракат қилиш;</p>

        <p>- Махфийлик сиёсатига мувофиқ фойдаланувчиларнинг шахсий маълумотларининг махфийлигини таъминлаш (оммага очиқ маълумотлар бундан мустасно);</p>

        <p>- «Роад24» платформасининг узлуксиз ва мақсадли ишлашини таъминлаш ва ривожлантириш;</p>

        <p>- ушбу Шартнома шартларига риоя қилиш;</p>

        <p>- Фойдаланувчилардан ушбу Шартнома бўйича ўз мажбуриятларини тўғри бажаришларини талаб қилиш.</p>

        <p>5.3.2. Фойдаланувчи қуйидаги мажбуриятларни бажаради:</p>

        <p>- ушбу Шартнома шартларига риоя қилиш;</p>

        <p>- аниқ ва тўғри маълумотларни тақдим этиш;</p>

        <p>- Сайт хизматларидан фойдаланиш жараёнида Ўзбекистон Республикасининг амалдаги қонунчилигига зид бўлган ва учинчи шахсларнинг қонуний манфаатларига зарар етказиши мумкин бўлган, шунингдек, реклама, эротик, порнографик ва ҳақоратомуз сўзлардан (маълумотлардан) фойдаланмаслик;</p>

        <p>- учинчи шахсларнинг (ҳар қандай) маълумотларга (материалларга), шарҳларга муаллифлик ҳуқуқлари, товар белгилари, бошқа интеллектуал мулк ҳуқуқларини бузмаслик;</p>

        <p>- кўрсатилган пуллик хизматлар учун тўловларни ўз вақтида тўлаш;</p>

        <p>- сайтдан ноқонуний мақсадларда фойдаланмаслик, уни ўзгартирмаслик;</p>

        <p>- бошқа шахсларнинг шахсий маълумотлари ва шахсий ҳаётининг махфийлигини сақлаш;</p>

        <p>- ушбу Шартномада белгиланган тартибда Платформадан фойдаланиш;</p>

        <p>- Платформанинг тегишли интерфейсларида Оператор томонидан эълон қилинган Платформа фойдаланувчилари учун хабарларни кузатиш, шунингдек, ушбу хабарлардаги талабларга риоя қилиш;</p>

        <p>- шахсий маълумотларини ва/ёки Платформага киришни таъминлайдиган бошқа маълумотлар учинчи шахсларга бермаслик (кейинги ўринларда "Платформага кириш учун маълумотлар" деб юритилади);</p>

        <p>
          - Платформага кириш учун ҳисоб маълумотларини учинчи шахслар томонидан ноқонуний равишда олиш ва улардан фойдаланишнинг олдини олиш бўйича зарур ташкилий-техник чора-тадбирларни кўриш;</p>

        <p>- Операторга Платформага кириш учун Ҳисоб маълумотларидан учинчи шахслар томонидан ноқонуний фойдаланишга доир фактлар ёки шубҳалар, шу жумладан мобил телефон рақамига кириш йўқолган (йўқолган, ўғирланган ёки йўқолган бошқа воситалар) тўғрисида дарҳол хабардор қилиш.</p>

        <p>- Платформа орқали ноқонуний молиявий операцияларни, ноқонуний савдони, жиноятдан олинган даромадларни легаллаштиришга ёки терроризмни молиялаштиришга қаратилган операцияларни ёки Ўзбекистон Республикаси қонунчилигида тақиқланган бошқа ҳаракатларни амалга оширмаслик;</p>

        <p>- фойдани мунтазам равишда олиш ёки даромадни яширишга қаратилган операцияларни амалга ошириш учун Платформадан фойдаланмаслик.</p>

        <p>5.4. Фойдаланувчи Платформага аниқ ва тўғри маълумотларни тақдим этиши шарт. Фойдаланувчи томонидан Сайт маъмуриятига маълумот тақдим этилмаган ёки тўлиқ тақдим этилмаган тақдирда, Сайт маъмурияти Фойдаланувчининг уланишини/рўйхатдан ўтишини бекор қилиши ёки уни Фойдаланувчи хоҳлаганидан бошқа шаклда рўйхатдан ўтказиши мумкин.</p>

        <p>5.5. Платформада бошқа фойдаланувчиларни чалғитишга қаратилган ҳаракатлар, шунингдек, қуйидагилар қатъиян ман этади:</p>

        <p>- бир нечта аккаунтлар яратиш / бир нечта АПИ-ларга қўшилиш, ўзини бошқа бировга ўхшатиш, айниқса бошқа компаниялар, юридик шахсларнинг номлари ёки тахаллуслари билан қайта-қайта рўйҳатдан ўтказиш;</p>

        <p>- ёлғон маълумотлар асосида қўшилиш, маълумотни бузиб кўрсатиш, ўзи ҳақида нотўғри маълумот жойлаштириш ва бошқалар;</p>

        <p>- ариза бланкларида зарур бўлмаган, кераксиз маълумотларни жойлаштириш;</p>

        <p>- хабар кимга қаратилганлигини аниқлаб бўлмайдиган даражада маълумотни бузиб кўрсатадиган шарҳлар қолдириш;</p>

        <p>- Сайт платформасидан фойдаланишда қўпол имло хатоларига йўл қўйиш;</p>

        <p>- Платформада уларнинг хизматларидан шахсан олмаган ёки фойдаланмаган шахслар ҳақида шарҳлар ва рейтинглар қолдириш.</p>

        <p>-хизмат кўрсатишда Ўзбекистон Республикасининг амалдаги қонунчилигини бузиш.</p>

        <p>5.6. Платформада жойлаштирилган барча обектлар, шу жумладан дизайн элементлари, матнлар, графикалар, расмлар, видеолар, скриптлар, дастурлар ва бошқа обектлар ва уларнинг комплекслари (кейинги ўринларда Контент деб юритилади) Сайт маъмурияти, сайт фойдаланувчилари ва бошқа ҳуқуқ эгалари муаллифлик ҳуқуқи билан ҳимояланган.</p>

        <p>5.7. Ҳеч қандай Контентни ушбу Шартномада кўрсатилганидан ҳоллардан ташқари ва республиканинг амалдаги қонунчилигига мувофиқ равишда тўлиқ ёки қисман нусхалаш (қайта нашр этиш), қайта ишлаш, тарқатиш, намойиш қилиш, нашр қилиш, юклаб олиш, узатиш, сотиш ёки сотиб олиш мумкин эмас. Ўзбекистон Республикаси, муаллифнинг олдиндан розилигисиз, муаллифнинг аниқ рухсати бўлган ҳоллар бундан мустаснодир.</p>

        <p>5.8. Фойдаланувчи бошқа сайтлар, маълумотлар базалари ва бошқа муаллифларнинг мазмуни билан боғлиқ интеллектуал фаолият натижаларини муаллифнинг розилигисиз, Платформада юклаш ёки нашр этиш ҳуқуқига эга эмас.</p>

        <p>5.9. Фойдаланувчи Ўзбекистон Республикасининг амалдаги қонунчилигига мувофиқ рўйхатдан ўтиш жараёнида тақдим этилган, шунингдек, шахсий кабинетида жойлаштирилган шахсий маълумотларни қайта ишлаш ва сақлаш, учинчи шахсларга тақдим этишга розилик берган деб ҳисобланади. Шахсий маълумотларни қайта ишлаш Ўзбекистон Республикаси қонун ҳужжатларига мувофиқ амалга оширилади.</p>

        <p>5.10. Сайт маъмурияти Фойдаланувчининг шахсий маълумотларини қайта ишлаш ва сақлаши мумкин, шунда у Сайт хизматларидан фойдаланиши ва Сайт хизматлари сифатини яхшилашга қаратилган тегишли маълумотларни таҳлил қилиши мумкин.</p>

        <p>
          5.11. Сайт маъмурияти Фойдаланувчининг идентификатори билан боғлиқ барча маълумотларни ноқонуний олиш, ўзгартириш, ошкор қилиш ёки йўқ қилишнинг олдини олиш чораларини кўради. Сайт маъмурияти фойдаланувчи маълумотлари билан фақат сайт функцияларини созлаш ва техник хизмат кўрсатиш учун зарур бўлгандагина бошқаларга ишлашга рухсат бериши мумкин.</p>

        <p>5.12. Сайт маъмурияти Фойдаланувчи томонидан тақдим этилган маълумотлардан, шунингдек, шахсий маълумотлардан Ўзбекистон Республикаси қонунчилиги талабларига риоя қилиш мақсадида (шу жумладан, Фойдаланувчининг қонунга хилоф ҳаракатларининг олдини олиш ёки уларга чек қўйиш мақсадида) фойдаланиши мумкин. Фойдаланувчилар тўғрисидаги маълумотлар Ўзбекистон Республикаси қонунчилигига мувофиқ, Шартнома бўйича ёки суднинг ҳамда қонун ҳужжатларида ваколат берилган бошқа ҳуқуқни муҳофаза қилувчи ва давлат органларининг талабига биноан бошқа шахсларга берилиши мумкин.</p>

        <p><strong>6. ПУЛЛИК ХИЗМАТЛАРНИНГ НАРХИ ВА ТЎЛОВ ТАРТИБИ</strong></p>

        <p>6.1. Платформа жисмоний ва юридик шахсларга пуллик хизматлар кўрсатади. Пуллик хизматларнинг нархи ва тўлов тартиби ушбу Шартномада ва/ёки Платформанинг ўзида белгиланган қоидалар билан тартибга солинади.</p>

        <p>6.2. Хизматларнинг нархи Маъмурият томонидан белгиланган тарифлар билан белгиланади. Платформада рўйхатдан ўтиш Фойдаланувчининг хизмат нархи, белгиланган тўлов қиймати ва ушбу Шартнома шартлари билан розилигини билдиради.</p>

        <p>6.3. Томонлар Платформанинг суғурта компаниялари, банклар, Ягона интерактив давлат хизматлари портали ва фойдаланувчи ташкилотлари ўртасида воситачи вазифасини бажаришини қабул қилади ва розилик беради. Платформа Фойдаланувчи ташкилотлари, суғурта компаниялари, банклар ва бошқалар томонидан белгиланган хизматлар нархи учун жавобгар эмас.</p>

        <p>6.4. Платформа воситачи сифатида Фойдаланувчилардан белгиланган тартибда белгиланган ҳақ олади. Узрли сабабларсиз тўловлар амалга оширилмаган тақдирда, Маъмурият Платформада Фойдаланувчиларнинг хизматларини чеклаш ёки бекор қилиш чораларини кўради.</p>

        <p>6.5. Платформадан фойдаланиш ва у билан мулоқот қилишда хизматни олувчилар ва тегишли компаниялар ўртасида келишмовчилик юзага келса, Маъмурият муаммоли вазиятга аралашмайди ёки ҳал қилмайди.</p>

        <p>6.6. Ҳамкор ташкилотлар ва Хизмат олувчи ўртасидаги пул маблағларини қайтариш бўйича саволлар ва низолар ушбу томонлар ўртасидаги алоҳида келишув билан ҳал қилинади. Бундай ҳолда, Маъмурият пул маблағларини қайтармайди, қопламайди ва қайтарилиши учун жавобгар эмас. Хизматларни кўрсатиш учун олинган тўлов қайтарилмайди.</p>

        <p><strong>7. ШАХСИЙ МАЪЛУМОТЛАРНИНГ МАХФИЙЛИК СИЁСАТИ</strong></p>

        <p>7.1. Маълумотлар махфийлиги сиёсати Платформада маълумотларнинг махфийлиги муносабатларини бошқаради.</p>

        <p>7.2. Маълумотларнинг махфийлиги қоидалари ушбу Шартноманинг бир қисми бўлган ва Шартноманинг 3.2-бандда кўрсатилган алоҳида ҳужжат билан тартибга солинади.</p>

        <p>7.3. Қоидалар Сайт рўйхатдан ўтиш ва фойдаланиш пайтида Фойдаланувчи ҳақида олиши мумкин бўлган барча маълумотларга нисбатан қўлланилади. Фойдаланувчи томонидан Сайтни рўйхатдан ўтказиш ва ундан фойдаланиш маълумотларнинг махфийлиги сиёсати ва унда фойдаланувчининг шахсий маълумотларини қайта ишлаш шартлари билан сўзсиз келишувни англатади. Агар фойдаланувчи ушбу шартларга рози бўлмаса, у Платформадан фойдаланмаслиги керак.</p>

        <p>7.4. Томонлар Ўзбекистон Республикасининг амалдаги қонунчилигига мувофиқ, Томонлар томонидан тижорат сири сифатида таснифланган ва битимни амалга ошириш жараёнида томонларга маълум бўлган маълумотларнинг, шу жумладан, чекланмаган ҳолда, махфийлигини сақлаш мажбуриятини оладилар:</p>

        <p>- тўловчилар, тўловлар ҳажмлари тўғрисидаги маълумотлар;</p>

        <p>- бошқарув тизимининг техник ҳужжатлари;</p>

        <p>- Маъмуриятнинг иш ҳақи миқдори ва Шартноманинг молиявий шартлари тўғрисидаги маълумотлар.</p>

        <p>7.5. Томонлар махфий маълумотларнинг ошкор этилиши учун Ўзбекистон Республикаси қонун ҳужжатларига мувофиқ жавобгар бўладилар.</p>

        <p><strong>8. ТОМОНЛАРНИНГ КАФОЛАТЛАРИ ВА ВАКОЛАТЛАРИ</strong></p>

        <p>
          8.1. «Роад24» платформаси ўз Фойдаланувчиларига кафолатланган сифатли хизматларни тақдим этади.</p>

        <p>8.2. Маъмурият Хизматларни олувчи ва ҳамкор ташкилотлар ўртасида тузилган олди-сотди битимлари, хизматлар кўрсатиш, ишларни бажариш ва бошқа битимларнинг тарафи эмас.</p>

        <p>8.3. Ҳар бир Томон шу билан бошқа Томонга қуйидагиларни билдиради ва кафолатлайди:</p>

        <p>- у белгиланган тартибда ташкил этилган ва рўйхатдан ўтган Ўзбекистон Республикаси Солиқ кодексининг 14 ва 15-моддалари билан хабардор этилган бўлиб, Ўзбекистон Республикаси қонунчилигига мувофиқ қонуний фаолият кўрсатаётган юридик шахс бўлиб, қонун ҳужжатларига мувофиқ тижорат, лицензияланадиган фаолиятни амалга ошириш ҳуқуқига эга. Ўзбекистон Республикасининг қонун ҳужжатлари яхши обрў-эътиборга эга, зарур имкониятларга, инвентарга, шунингдек, ўз ҳолатидаги мутахассисларга эга, Маъмурият ва тегишли давлат органлари олдидаги ўз мажбуриятларини бажариш имкониятини кафолатлайди;</p>

        <p>- ушбу Шартномани имзолаган шахс ушбу ҳаракат учун барча зарур ваколатларга эга;</p>

        <p>- томонлар ўз мажбуриятлари бўйича учинчи шахслар олдида жавоб бермайдилар;</p>

        <p>- ҳеч бир томон тугатилиш жараёнида эмас. Томонлар учун ушбу Шартноманинг тўғри бажарилишига тўсқинлик қиладиган учинчи шахсларнинг тақиқлари, гаровлари, юклари ёки ҳуқуқларига эга эмаслар.</p>

        <p>8.4. Ҳеч қайси тараф, на унинг директорлари, мансабдор шахслари ёки ходимлари санкция обекти ҳисобланмайди, уларга эгалик қилмайди ёки назорат қилинмайди ёки бошқа рухсат этилган шахс ёки юридик шахс номидан ҳаракат қилмайди.</p>

        <p>8.5. Томонлар тарафидан Шартнома бўйича ўз мажбуриятларини бажариш учун жалб қилинган ҳар қандай жисмоний ёки юридик шахслар, шу жумладан уларнинг ходимлари, агентлари, маслаҳатчилари, пудратчилар/етказиб берувчилар ва субпудратчилар/пудратчилар Ўзбекистоннинг "Коррупсияга қарши курашиш тўғрисида"ги қонуни талабларига мувофиқ (01.03.2017 й., ЎРҚ-419-сон):</p>

        <p>- бу мансабдор шахс ўз хизмат вазифаларини бажаришда бирор-бир ҳаракат ёки ҳаракацизликни амалга ошириши учун пора ёки бошқа коррупцион тўловларни, шу жумладан бирор бир давлат мансабдор шахсига таклиф қилмаслиги, ваъда қилмаслиги, бермаслиги, талаб қилмаслиги ёки олмаслиги;</p>

        <p>- тарафлар, уларнинг бевосита ёки билвосита акциядорлари (иштирокчилари), директорлари, мансабдор шахслари ёки ходимлари томонидан қасддан ёки эҳтиёцизлик оқибатида қонун бузилишига олиб келиши мумкин бўлган хатти-ҳаракатларга йўл қўймаслик;</p>

        <p>- агар Томонлардан бирортасида Ўзбекистон Республикасининг коррупцияга қарши қонунчилиги талаблари бузилганлиги (ёки мумкин бўлган бузилиши) тўғрисида асосли шубҳалар мавжуд бўлса, улар бошқа Томон ва унинг вакиллари билан виждонан ҳамкорлик қилишлари шарт.</p>

        <p><strong>9. ТОМОНЛАРНИНГ МАЪЛУМОТИ</strong></p>

        <p>9.1. Томонлар ушбу Шартнома шартларини бажармаганлик ёки лозим даражада бажармаганлик учун Ўзбекистон Республикасининг амалдаги қонунчилигига мувофиқ жавобгар бўладилар.</p>

        <p>9.2. Фойдаланувчи ушбу Шартнома бўйича мажбуриятларни бажармаслик ёки лозим даражада бажармаслик натижасида юзага келадиган ҳар қандай зарар ёки бошқа нохуш оқибатлар учун жавобгардир.</p>

        <p>9.3. Ишончсиз ҳаволалардан фойдаланадиган қурилмага шахсий маълумотларини киритишда ёки зарарли дастурлар ва иловалар хавфи остида бўлганида фойдаланувчи якка тартибда жавобгар бўлади.</p>

        <p>9.4. Сайт, агар номувофиқлик ёки Шартнома шартларига риоя қилмаслик Фойдаланувчи томонидан соди этилган бўлса, Шартнома шартларига риоя қилмаслик учун жавобгар эмас,.</p>

        <p>9.5. Фойдаланувчи ушбу Шартнома шартлари, шунингдек, фаоллаштириш вақтида уларнинг таклифлари билан ўз вақтида танишмаганлиги ва (ёки) ўз вақтида танишмаганлиги сабабли Фойдаланувчига етказилган зарар учун Сайт жавобгар эмас.</p>

        <p>
          9.6. Агар бундай номувофиқлик Сайтга боғлиқ бўлмаган сабабларга кўра юзага келган бўлса, Сайт Шартнома шартларига риоя қилмаслик учун жавобгар эмас. Платформани назорат қилиш доираси Сайтнинг ўз фаолияти билан чекланган ва учинчи шахсларнинг хатолари ёки давлат органлари ёки суд органлари томонидан қўйилган тақиқлар ва чекловлар туфайли ишдаги узилишлар натижасида юзага келган носозликлар учун жавобгарликни ўз ичига олмайди.</p>

        <p>9.7. Томонлар ушбу Шартнома бўйича мажбуриятларни бажармаганлик ёки лозим даражада бажармаганлик учун жавобгарликдан озод қилинадилар, агар бунда бундай бажармаслик фавқулодда вазиятлар натижасида юзага келган форс-мажор ҳолатларининг натижаси бўлса, Томон олдиндан кўра олмаган ёки шартнома тузилгандан кейин олдини олиш учун оқилона чоралар кўрган бўлса.</p>

        <p>9.8. Оператор Шартнома бўйича ўз мажбуриятларини тўлиқ ёки қисман бажармаганлик учун жавобгарликдан тўлиқ озод қилинади, агар бажарилмаслиги ёки лозим даражада бажарилмаслиги техноген хусусиятдаги форс-мажор ҳолатлари, хусусан: авария (ишдаги узулиш) натижасида юзага келган бўлса. Платформанинг ишлаши учун фойдаланиладиган электр ёки компютер тармоқлари ёки бошқа электр алоқа тизимлари, шунингдек, учинчи шахсларнинг Платформа фаолиятини бузишга қаратилган қасддан ноқонуний ҳаракатлари натижасида юзага келган бўлса.</p>

        <p>9.9. Йўқолган, ўғирланган ёки бошқа ҳолларда фойдаланувчи Платформа уланган мобил (абонент) телефон рақамига кириш имконига эга бўлмаса, Фойдаланувчи учинчи шахсларнинг ушбу платформадан фойдаланиш билан боғлиқ ҳар қандай ҳаракатлари учун барча хавф ва жавобгарликни ўз зиммасига олади.</p>

        <p>9.10. Оператор фойдаланувчининг техник платформалари ва транспорт тармоқлари ёки алоқа тармоқларининг ишлашидаги кечикишлар ва узилишлар учун жавобгар эмас, уларнинг пайдо бўлиши Операторнинг айби билан бўлмайди.</p>

        <p>9.11. Оператор Фойдаланувчи томонидан Платформа ва/ёки бошқа шахсий маълумотларга кириш учун ўз Ҳисоб маълумотларини ошкор қилганлиги сабабли Фойдаланувчига етказилган ҳар қандай зарар учун жавобгар эмас.</p>

        <p>9.12. Оператор Ҳамкор ташкилотлар томонидан Фойдаланувчига тақдим этилаётган хизматларнинг сифати, шунингдек, Фойдаланувчилар томонидан сотиб олинган хизматларнинг фойдаланувчиларга тақдим этилиши билан боғлиқ жараёнда ва фойдаланувчилар олдидаги мажбуриятлари учун жавобгар эмас.</p>

        <p>9.13. Фойдаланувчи ўзи томонидан Платформада амалга оширилган барча операциялар учун жавобгардир.</p>

        <p>9.14. Оператор бошқа ташкилотлар, фойдаланувчилар томонидан тақдим этилган алоқа линияларининг сифати ва унга тегишли бўлмаган бошқа фойдаланувчиларга тегишли бўлган бошқа, шу жумладан транзит, маълумотларни узатиш тармоқлари тугунларининг жиҳозларининг ишлаши учун жавобгар эмас.</p>

        <p>9.15. Фойдаланувчи Платформа уланган Фойдаланувчи номидан содир этилган учинчи шахсларнинг ҳар қандай ҳаракатлари, шунингдек, унинг қурилмасида ўрнатилган иловалар ва дастурлардан фойдаланиш ёки Платформага кириш учун унинг Ҳисоб маълумотларидан фойдаланиш учун таваккалчилик ва жавобгарликни ўз зиммасига олади.</p>

        <p><strong>10. ШАРТНОМАНИ ТУГАТИШ</strong></p>

        <p>10.1. Шартнома қуйидаги ҳолларда бекор қилиниши мумкин:</p>

        <p>- Томонлар келишуви бўйича;</p>

        <p>- Шартномада назарда тутилган келишмовчиликларни ҳал қилиш тартибини ҳисобга олган ҳолда, бошқа Томон тарафидан Битимнинг бир ёки бир нечта шартлари такроран бузилган тақдирда бир томонлама;</p>

        <p>- Томонлардан бирортасининг ташаббуси билан, Шартномани бекор қилишнинг кутилаётган санасидан 30 (ўттиз) календар кунидан кечиктирмай бошқа Томонни хабардор қилиш шарти билан. Хабарнома қоғозда ёзма равишда амалга оширилиши ва Шартномани бекор қилиш сабабини кўрсатиши керак.</p>

        <p>10.2. Маъмурият, агар Фойдаланувчи ушбу Шартнома шартларини бузса, Платформадаги Фойдаланувчи аккаунтини ўчириш ва Шартномани бекор қилиш ҳуқуқини ўзида сақлаб қолади.</p>

        <p>10.3. Ушбу Шартнома қонун ҳужжатларида назарда тутилган бошқа асосларда ҳам бекор қилиниши мумкин.</p>

        <p>
          10.4. Фойдаланувчи томонидан шахсий кабинет ўчирилганда шартнома бекор қилинган ҳисобланади. Шахсий кабинет ўчиришдан олдин, томонлар Шартномани бекор қилишдан олдин пайдо бўлган мажбуриятларни бажаришлари керак.</p>

        <p><strong>11. НИЗОЛАРНИ ҲАЛ ҚИЛИШ ТАРТИБИ</strong></p>

        <p>11.1. Ушбу Шартномадан келиб чиқадиган ёки у билан боғлиқ ҳар қандай низо тинч йўл билан - музокаралар йўли билан ёки фойдаланувчининг ёзма илтимосига биноан мурожаат қилиш тартибида ҳал қилинади. Мурожаатни кўриб чиқиш муддатлари ва унинг мазмунига қўйиладиган талаблар ушбу Шартнома ва Ўзбекистон Республикасининг амалдаги қонунчилиги билан белгиланади.</p>

        <p>11.2. Агар низо даъво тартибида ҳал этилмаса, у Ўзбекистон Республикасининг амалдаги қонунчилигида белгиланган тартибда тегишли суд томонидан кўриб чиқилиши мумкин.</p>

        <p><strong>12. БОШҚА ШАРТЛАР</strong></p>

        <p>12.1. Платформа тегишли хизматларни биргаликда кўрсатувчи ташкилотларнинг маълумотлар базалари билан интеграцияланган ва фойдаланувчи маълумотлари ушбу ташкилотлар томонидан қабул қилинади. Фойдаланувчилар ҳақидаги маълумотлар ҳам ушбу ташкилотлар томонидан Платформага тақдим этилади.</p>

        <p>12.2. Фойдаланувчи ва учинчи шахслар томонидан платформа таркибидан нотижорат мақсадларда фойдаланишга барча муаллифлик ҳуқуқлари, муаллифнинг исми ва Контент ўзгаришсиз қолиши шарти билан рухсат этилади.</p>

        <p>12.3. Учинчи томон сайтлари ва контенти:</p>

        <p>12.3.1. Сайтда Интернетдаги бошқа сайтларда (учинчи шахсларга тегишли сайтлар) мақолалар, фотосуратлар, иллюстрациялар, графикалар, маълумотлар, иловалар, дастурлар ва Ўзбекистон Республикаси қонунчилиги билан ҳимояланган интеллектуал фаолият натижаси бўлган бошқа контентга ҳаволалар бўлиши мумкин. ).</p>

        <p>12.3.2. Учинчи шахсларга тегишли деб кўрсатилган контент Сайт маъмурияти томонидан ҳеч қандай талабларга (ишончлилик, тўлиқлик, холислик) мувофиқлиги текширилмайди. Сайт маъмурияти Платформада эълон қилинган учинчи шахслар тўғрисидаги фойдаланувчи томонидан рухсат этилган ёки учинчи шахс контентидан олинган ҳар қандай маълумотлар, шунингдек учинчи шахслар томонидан билдирилган ёки уларнинг Контентида маълумотлар учун жавобгар эмас.</p>

        <p>12.3.3. Платформада жойлаштирилган ҳар қандай бошқа Платформалар тўғрисидаги маълумотлар, маҳсулот, хизмат ҳақидаги тижорат ёки нотижорат характердаги ҳар қандай маълумотлар уларнинг Сайт маъмурияти томонидан тавсия этилган мазмунни билдирмайди.</p>

        <p>12.4. Ушбу Таклифни қабул қилиб, Фойдаланувчи Шартноманинг барча шартларига сўзсиз розилигини тасдиқлайди ва уларга риоя қилиш мажбуриятини олади.</p>

        <p>12.5. Сайтнинг тегишли платформалари ва бўлимларида улардан фойдаланиш қоидалари, кўрсатмалари, маълумотлари ва тавсиялари ушбу Шартноманинг ажралмас қисми ҳисобланади. Ушбу Платформалар ва Сайтнинг бўлимлари ва функцияларидан фойдаланган ҳолда, Фойдаланувчи улардаги қоидалар, кўрсатмалар, маълумотлар ва тавсияларга рози бўлади.</p>

        <p>12.6. Ушбу Шартноманинг амал қилиш муддати чекланмаган. Агар томонлардан ҳеч бири шартномавий муносабатларни бекор қилиш истагини билдирмаса, шартнома кейинги йилга узайтирилган ҳисобланади.</p>

        <p>12.7. Ушбу Оммавий оферта расмий ҳужжат бўлиб, унга илова қилинган ҳужжатлар Оммавий офертанинг ажралмас қисми ҳисобланади.</p>

        <p>12.8. Томонлар шахсий маълумотлари ўзгарган тақдирда бир-бирларини дарҳол хабардор қилишлари ва Платформага ўзгартиришлар киритишлари шарт.</p>

        <p>12.9. Шартномада кўрсатилмаган бошқа муносабатлар ушбу Битим мақсадларига мувофиқ Ўзбекистон Республикасининг амалдаги қонунчилиги билан тартибга солинади.</p>
      </div>
    </>
  );
}

export default PrivacyPolicy;
